// src/components/Layout.js

import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import authService from '../services/authService';
import '../styles/Layout.css';

function Layout() {
  const user = authService.getCurrentUser();

  return (
    <>
      {user && <Header username={user.username} />}
      <div className="layout-content">
        <Outlet />
      </div>
    </>
  );
}

export default Layout;
