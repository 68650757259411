// src/components/MainMenu.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/MainMenu.css';
import logoMalaka from '../images/logo_malaka.png'; // Importa el logo

function MainMenu() {
    const navigate = useNavigate();

    const handleLauroClick = () => {
        // Por ahora, no hace nada
        alert('Lauro Club de Viaje aún no está implementado.');
    };

    const handleMalakaClick = () => {
        navigate('/malaka');
    };

    return (
        <div className="main-menu-container">
            <h2>Menú Principal</h2>
            <div className="buttons-container">
                <button className="main-menu-button" onClick={handleLauroClick}>
                    Lauro Club de Viaje
                </button>
                <button className="main-menu-button malaka-button" onClick={handleMalakaClick}>
                    <img src={logoMalaka} alt="Malaka Viajes" className="malaka-logo" />
                </button>
            </div>
        </div>
    );
}

export default MainMenu;