// src/components/Malaka/CajaViewer.js

import React, { useState, useEffect, useMemo, useRef } from 'react';
import axiosInstance from '../../services/axiosConfig';
import styles from '../../styles/Malaka/CajaViewer.module.css';
import { FaSpinner, FaTimes } from 'react-icons/fa'; // Iconos adicionales

function CajaViewer() {
  const [payments, setPayments] = useState([]);
  const [charges, setCharges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Estados para los filtros
  const [filterType, setFilterType] = useState('all'); // 'all', 'Pago', 'Cobro'
  const [filterSupplier, setFilterSupplier] = useState('all'); // 'all' o supplierId
  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');
  const [filterExpedient, setFilterExpedient] = useState(''); // Número o nombre de expediente

  // Estados para el ordenamiento
  const [sortColumn, setSortColumn] = useState('date'); // Columna inicial de ordenamiento
  const [sortOrder, setSortOrder] = useState('desc');   // Orden inicial: descendente

  // Estado para la búsqueda global
  const [searchQuery, setSearchQuery] = useState('');

  // Estados para la selección de filas
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);

  // Estados para la selección por arrastre
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionBox, setSelectionBox] = useState(null);
  const startPointRef = useRef(null);

  // Refs para las filas de la tabla
  const tableRef = useRef(null);
  const rowRefs = useRef([]);

  // Estado para la ordenación
  const [sortConfig, setSortConfig] = useState({
    key: 'date', // Columna por defecto para ordenar
    direction: 'descending', // 'ascending' o 'descending'
  });

  // Función para parsear la fecha del formato "DD/MM/YYYY" a objeto Date
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  // Fetch Payments
  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await axiosInstance.get('/payments');
        setPayments(response.data);
      } catch (err) {
        console.error('Error al obtener pagos:', err);
        setError('Error al obtener pagos.');
      }
    };

    fetchPayments();
  }, []);

  // Fetch Charges
  useEffect(() => {
    const fetchCharges = async () => {
      try {
        const response = await axiosInstance.get('/charges');
        setCharges(response.data);
      } catch (err) {
        console.error('Error al obtener cobros:', err);
        setError('Error al obtener cobros.');
      }
    };

    fetchCharges();
  }, []);

  // Actualizar el estado de carga
  useEffect(() => {
    if ((payments.length > 0 || charges.length > 0) && !error) {
      setLoading(false);
    }
  }, [payments, charges, error]);

  // Obtener la lista única de proveedores para el filtro
  const uniqueSuppliers = useMemo(() => {
    const suppliers = [...payments, ...charges]
      .filter((item) => item.type === 'Pago' && item.supplierId)
      .map((item) => ({ id: item.supplierId, name: item.supplierName }));
    // Eliminar duplicados
    return suppliers.filter(
      (supplier, index, self) =>
        index === self.findIndex((s) => s.id === supplier.id)
    );
  }, [payments, charges]);

  // Combinar y ordenar los datos
  const combinedData = useMemo(() => {
    const mappedPayments = payments.map((payment, idx) => ({
      id: `payment-${idx}`, // ID único para la selección
      type: 'Pago',
      supplierName: payment.supplierName,
      supplierId: payment.supplierId,
      amount: parseFloat(payment.amount),
      date: parseDate(payment.date),
      description: payment.description,
      method: payment.method,
      expedientID: payment.expedientID,
      expedientName: payment.expedientName,
    }));

    const mappedCharges = charges.map((charge, idx) => ({
      id: `charge-${idx}`, // ID único para la selección
      type: 'Cobro',
      amount: parseFloat(charge.amount),
      date: parseDate(charge.date),
      description: charge.description,
      method: charge.method,
      expedientID: charge.expedientID,
      expedientName: charge.expedientName,
    }));

    const allData = [...mappedPayments, ...mappedCharges];

    // Aplicar filtros
    const filteredData = allData.filter((item) => {
      // Filtro por tipo
      if (filterType !== 'all' && item.type !== filterType) {
        return false;
      }

      // Filtro por proveedor
      if (filterSupplier !== 'all') {
        if (item.type !== 'Pago') return false; // Solo pagos tienen proveedor
        if (item.supplierId !== parseInt(filterSupplier)) {
          return false;
        }
      }

      // Filtro por fecha
      if (filterStartDate) {
        const start = new Date(filterStartDate);
        if (item.date < start) {
          return false;
        }
      }

      if (filterEndDate) {
        const end = new Date(filterEndDate);
        // Ajustar la fecha para incluir todo el día
        end.setHours(23, 59, 59, 999);
        if (item.date > end) {
          return false;
        }
      }

      // Filtro por expediente
      if (filterExpedient) {
        const expedientLower = filterExpedient.toLowerCase();
        const idMatch = item.expedientID.toString().includes(expedientLower);
        const nameMatch = item.expedientName
          ? item.expedientName.toLowerCase().includes(expedientLower)
          : false;
        if (!idMatch && !nameMatch) {
          return false;
        }
      }

      // Búsqueda global
      if (searchQuery) {
        const queryLower = searchQuery.toLowerCase();
        const typeMatch = item.type.toLowerCase().includes(queryLower);
        const supplierNameMatch = item.supplierName
          ? item.supplierName.toLowerCase().includes(queryLower)
          : false;
        const expedientNameMatch = item.expedientName
          ? item.expedientName.toLowerCase().includes(queryLower)
          : false;
        const descriptionMatch = item.description
          ? item.description.toLowerCase().includes(queryLower)
          : false;
        if (
          !typeMatch &&
          !supplierNameMatch &&
          !expedientNameMatch &&
          !descriptionMatch
        ) {
          return false;
        }
      }

      return true;
    });

    // Ordenar según sortConfig
    if (sortConfig !== null) {
      filteredData.sort((a, b) => {
        let aValue = a[sortColumn];
        let bValue = b[sortColumn];

        // Manejar valores undefined o null
        if (aValue == null) aValue = '';
        if (bValue == null) bValue = '';

        // Convertir a mayúsculas para orden alfabético insensible a mayúsculas
        if (typeof aValue === 'string') aValue = aValue.toUpperCase();
        if (typeof bValue === 'string') bValue = bValue.toUpperCase();

        if (aValue < bValue) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredData;
  }, [
    payments,
    charges,
    filterType,
    filterSupplier,
    filterStartDate,
    filterEndDate,
    filterExpedient,
    searchQuery,
    sortColumn,
    sortOrder,
  ]);

  // Función para formatear la fecha para mostrar
  const formatDisplayDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Función para formatear el método de pago
  const formatMethod = (method) => {
    const methodMapping = {
      cash: 'Efectivo',
      bankBBVA: 'Banco BBVA',
      bankSantander: 'Banco Santander',
      bankUnicaja: 'Banco Unicaja',
      bankCajamar: 'Banco Cajamar',
      // Agrega más métodos si es necesario
    };
    return methodMapping[method] || method;
  };

  // Función para limpiar todos los filtros y la búsqueda
  const clearFilters = () => {
    setFilterType('all');
    setFilterSupplier('all');
    setFilterStartDate('');
    setFilterEndDate('');
    setFilterExpedient('');
    setSearchQuery('');
  };

  // Manejar la selección de una fila
  const handleRowSelect = (index) => {
    const item = combinedData[index];
    if (!item) return;

    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);

      if (window.event.shiftKey && lastSelectedIndex !== null) {
        // Selección de rango con Shift+clic
        const start = Math.min(lastSelectedIndex, index);
        const end = Math.max(lastSelectedIndex, index);
        for (let i = start; i <= end; i++) {
          newSelectedRows.add(combinedData[i].id);
        }
      } else if (window.event.ctrlKey || window.event.metaKey) {
        // Selección múltiple con Ctrl/Cmd+clic
        if (newSelectedRows.has(item.id)) {
          newSelectedRows.delete(item.id);
        } else {
          newSelectedRows.add(item.id);
        }
        setLastSelectedIndex(index);
      } else {
        // Selección única
        if (newSelectedRows.has(item.id)) {
          newSelectedRows.delete(item.id);
        } else {
          newSelectedRows.clear();
          newSelectedRows.add(item.id);
        }
        setLastSelectedIndex(index);
      }

      return newSelectedRows;
    });
  };

  // Calcular la suma de los montos seleccionados
  const selectedSum = useMemo(() => {
    return combinedData
      .filter((item) => selectedRows.has(item.id))
      .reduce((acc, item) => acc + item.amount, 0);
  }, [combinedData, selectedRows]);

  // Manejar eventos de selección por arrastre
  const handleMouseDown = (e) => {
    // Solo izquierda del ratón
    if (e.button !== 0) return;

    // Verificar si el clic está dentro de la tabla
    const tableRect = tableRef.current.getBoundingClientRect();
    if (
      e.clientX < tableRect.left ||
      e.clientX > tableRect.right ||
      e.clientY < tableRect.top ||
      e.clientY > tableRect.bottom
    ) {
      return; // No iniciar selección si el clic no está en la tabla
    }

    setIsSelecting(true);
    const startX = e.clientX;
    const startY = e.clientY;
    startPointRef.current = { x: startX, y: startY };

    setSelectionBox({
      x: startX,
      y: startY,
      width: 0,
      height: 0,
    });

    // Prevenir selección de texto mientras se selecciona
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (!isSelecting) return;

    const currentX = e.clientX;
    const currentY = e.clientY;

    const startX = startPointRef.current.x;
    const startY = startPointRef.current.y;

    const scale = window.devicePixelRatio || 1; // Obtener la escala actual

    const x = Math.min(startX, currentX) / scale;
    const y = Math.min(startY, currentY) / scale;
    const width = Math.abs(currentX - startX) / scale;
    const height = Math.abs(currentY - startY) / scale;

    setSelectionBox({
      x,
      y,
      width,
      height,
    });

    // Determinar qué filas están dentro de la caja de selección
    const selectionBoxRect = {
      left: x,
      top: y,
      right: x + width,
      bottom: y + height,
    };

    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);

      combinedData.forEach((item, index) => {
        const rowRef = rowRefs.current[index];
        if (rowRef) {
          const rect = rowRef.getBoundingClientRect();

          // Ajustar las coordenadas según la escala
          const adjustedRowRect = {
            left: rect.left / scale,
            top: rect.top / scale,
            right: rect.left + rect.width / scale,
            bottom: rect.top + rect.height / scale,
          };

          // Verificar intersección
          const isIntersecting =
            !(selectionBoxRect.left > adjustedRowRect.right ||
              selectionBoxRect.right < adjustedRowRect.left ||
              selectionBoxRect.top > adjustedRowRect.bottom ||
              selectionBoxRect.bottom < adjustedRowRect.top);

          if (isIntersecting) {
            newSelectedRows.add(item.id);
          } else {
            newSelectedRows.delete(item.id);
          }
        }
      });

      return newSelectedRows;
    });
  };

  const handleMouseUp = () => {
    if (!isSelecting) return;

    setIsSelecting(false);
    setSelectionBox(null);
    startPointRef.current = null;
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      // Si se hace clic en la misma columna, alternar el orden
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Si se hace clic en una columna diferente, establecer esa columna y orden ascendente
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const renderSortArrow = (column) => {
    if (sortColumn !== column) return null;

    if (sortOrder === 'asc') {
      return <span className={styles.sortArrow}>&uarr;</span>;
    } else {
      return <span className={styles.sortArrow}>&darr;</span>;
    }
  };

  // Añadir y remover eventos de mouse al documento
  useEffect(() => {
    if (isSelecting) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isSelecting, selectionBox]);

  // Manejar clics fuera de la tabla para limpiar selección
  useEffect(() => {
    const handleGlobalClick = (e) => {
      if (!tableRef.current) return;

      if (!tableRef.current.contains(e.target)) {
        setSelectedRows(new Set());
      }
    };

    document.addEventListener('mousedown', handleGlobalClick);

    return () => {
      document.removeEventListener('mousedown', handleGlobalClick);
    };
  }, []);

  // Manejar la tecla ESC para limpiar la selección
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setSelectedRows(new Set()); // Limpiar las filas seleccionadas
        setIsSelecting(false);      // Detener cualquier selección en curso
        setSelectionBox(null);      // Remover la caja de selección si está activa
      }
    };

    // Añadir el escuchador de eventos al montar el componente
    document.addEventListener('keydown', handleKeyDown);

    // Remover el escuchador de eventos al desmontar el componente
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Función para manejar la ordenación
  const requestSort = (key, direction) => {
    setSortConfig({ key, direction });
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <FaSpinner className={styles.spinner} />
        <p>Cargando datos...</p>
      </div>
    );
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  return (
    <div
      className={styles.cajaViewerContainer}
      onMouseDown={handleMouseDown}
      onContextMenu={(e) => e.preventDefault()} // Prevenir menú contextual
    >
      <h2>Listado Completo de Pagos y Cobros</h2>

      {/* Sección de Filtros */}
      <div className={styles.filtersContainer}>
        {/* Filtro por Tipo */}
        <div className={styles.filterGroup}>
          <label htmlFor="filterType">Tipo:</label>
          <select
            id="filterType"
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <option value="all">Todos</option>
            <option value="Pago">Pago</option>
            <option value="Cobro">Cobro</option>
          </select>
        </div>

        {/* Filtro por Proveedor (solo para Pagos) */}
        <div className={styles.filterGroup}>
          <label htmlFor="filterSupplier">Proveedor:</label>
          <select
            id="filterSupplier"
            value={filterSupplier}
            onChange={(e) => setFilterSupplier(e.target.value)}
            disabled={filterType === 'Cobro'}
          >
            <option value="all">Todos</option>
            {uniqueSuppliers.map((supplier) => (
              <option key={supplier.id} value={supplier.id}>
                {supplier.name}
              </option>
            ))}
          </select>
        </div>

        {/* Filtro por Fecha de Inicio */}
        <div className={styles.filterGroup}>
          <label htmlFor="filterStartDate">Fecha Desde:</label>
          <input
            type="date"
            id="filterStartDate"
            value={filterStartDate}
            onChange={(e) => setFilterStartDate(e.target.value)}
          />
        </div>

        {/* Filtro por Fecha de Fin */}
        <div className={styles.filterGroup}>
          <label htmlFor="filterEndDate">Fecha Hasta:</label>
          <input
            type="date"
            id="filterEndDate"
            value={filterEndDate}
            onChange={(e) => setFilterEndDate(e.target.value)}
          />
        </div>

        {/* Filtro por Expediente */}
        <div className={styles.filterGroup}>
          <label htmlFor="filterExpedient">Expediente:</label>
          <input
            type="text"
            id="filterExpedient"
            placeholder="Número o Nombre"
            value={filterExpedient}
            onChange={(e) => setFilterExpedient(e.target.value)}
          />
        </div>

        {/* Campo de Búsqueda Global */}
        <div className={styles.filterGroup}>
          <label htmlFor="searchQuery">Buscar:</label>
          <div className={styles.searchContainer}>
            <input
              type="text"
              id="searchQuery"
              placeholder="Buscar en todos los campos"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {searchQuery && (
              <FaTimes
                className={styles.clearSearchIcon}
                onClick={() => setSearchQuery('')}
                title="Limpiar búsqueda"
              />
            )}
          </div>
        </div>

        {/* Botón para Limpiar Filtros */}
        <div className={styles.filterGroup}>
          <button
            type="button"
            className={styles.clearFiltersButton}
            onClick={clearFilters}
          >
            Limpiar Filtros
          </button>
        </div>
      </div>

      {/* Tabla de Pagos y Cobros */}
      <table className={styles.cajaTable} ref={tableRef}>
        <thead>
          <tr>
            <th onClick={() => handleSort('type')}>
              Tipo {renderSortArrow('type')}
            </th>
            <th onClick={() => handleSort('supplierName')}>
              Proveedor {renderSortArrow('supplierName')}
            </th>
            <th onClick={() => handleSort('amount')}>
              Monto (€) {renderSortArrow('amount')}
            </th>
            <th onClick={() => handleSort('date')}>
              Fecha {renderSortArrow('date')}
            </th>
            <th onClick={() => handleSort('method')}>
              Método {renderSortArrow('method')}
            </th>
            <th onClick={() => handleSort('description')}>
              Descripción {renderSortArrow('description')}
            </th>
            <th onClick={() => handleSort('expedientID')}>
              Expediente {renderSortArrow('expedientID')}
            </th>
          </tr>
        </thead>
        <tbody>
          {combinedData.length > 0 ? (
            combinedData.map((item, index) => (
              <tr
                key={item.id}
                className={selectedRows.has(item.id) ? styles.selectedRow : ''}
                onClick={(e) => handleRowSelect(index)}
                ref={(el) => (rowRefs.current[index] = el)}
              >
                <td>{item.type}</td>
                <td>{item.type === 'Pago' ? item.supplierName : '-'}</td>
                <td>{item.amount.toFixed(2)}</td>
                <td>{formatDisplayDate(item.date)}</td>
                <td>{formatMethod(item.method)}</td>
                <td>{item.description}</td>
                <td>
                  #{item.expedientID} - {item.expedientName}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className={styles.noData}>
                No se encontraron resultados.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Caja de Selección (Marquee) */}
      {isSelecting && selectionBox && (
        <div
          className={styles.selectionBox}
          style={{
            left: selectionBox.x,
            top: selectionBox.y,
            width: selectionBox.width,
            height: selectionBox.height,
          }}
        ></div>
      )}

      {/* Sección de Autosuma */}
      <div className={styles.autosumaContainer}>
        <span>Monto Seleccionado: </span>
        <span className={styles.sumAmount}>€ {selectedSum.toFixed(2)}</span>
      </div>
    </div>
  );
}

export default CajaViewer;