// src/components/RoomingListPDF.js

import React, { useMemo } from 'react';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font,
    Image,
} from '@react-pdf/renderer';
import logo from '../../images/logo_malaka.png';

// Registrar una fuente personalizada si es necesario
Font.register({
    family: 'Helvetica',
    fonts: [
        { src: 'https://fonts.gstatic.com/s/helvetica/v11/Helvetica-Regular.ttf' },
        { src: 'https://fonts.gstatic.com/s/helvetica/v11/Helvetica-Bold.ttf', fontWeight: 'bold' },
    ],
});

// Definir estilos
const styles = StyleSheet.create({
    logo: {
        width: 100,
        height: 50,
        marginBottom: 10,
    },
    headerTop: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: 10,
    },
    page: {
        padding: 40,
        fontFamily: 'Helvetica',
        fontSize: 10,
        color: '#333',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: 10,
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    hotelInfo: {
        textAlign: 'right',
        fontSize: 11,
        justifyContent: 'space-between',
    },
    regimeBox: {
        border: '1 solid #000',
        padding: 5,
        marginBottom: 10,
        textAlign: 'center',
        fontSize: 14,
    },
    referenceInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 10,
        borderBottom: '1 solid #000',
        paddingBottom: 5,
    },
    referenceText: {
        fontSize: 10,
    },
    roomTypeTitle: {
        fontWeight: 'bold',
        marginVertical: 5,
        textAlign: 'center',
    },
    roomContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 10,
    },
    roomItem: {
        width: '48%', // Ajustado para agregar espaciado entre columnas
        padding: 5,
        marginBottom: 5,
    },
    passengerNames: {
        marginTop: 2,
        textAlign: 'left', // Ajusta según preferencia
        lineHeight: 1.2,
    },
    footer: {
        marginTop: 20,
        fontSize: 10,
        textAlign: 'center',
    },
    totalRooms: {
        fontWeight: 'bold',
        marginTop: 10,
    },
    // Nuevos estilos para ExpedientNumber y GroupName
    expedientInfo: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: 10,
    },
    expedientNumber: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    groupName: {
        fontSize: 12,
    },
});

const formatDate = (dateString) => {
    if (!dateString) return 'No especificado';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return 'Fecha inválida';
    }
    const day = date.getDate().toString().padStart(2,'0');
    const month = (date.getMonth()+1).toString().padStart(2,'0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

const RoomingListPDF = ({ hotelInfo, roomData, expedientNumber, groupName }) => {
    const groupedRooms = useMemo(() => {
        const groups = {
            SINGLE: [],
            DOUBLE: [],
            TRIPLE: [],
        };
        roomData.rooms.forEach((room) => {
            if (groups[room.roomType]) {
                groups[room.roomType].push(room);
            }
        });
        return groups;
    }, [roomData.rooms]);

    const totalSingleRooms = groupedRooms.SINGLE.length;
    const totalDoubleRooms = groupedRooms.DOUBLE.length;
    const totalTripleRooms = groupedRooms.TRIPLE.length;
    const totalRooms = totalSingleRooms + totalDoubleRooms + totalTripleRooms;
    const totalPersons = roomData.rooms.reduce((acc, room) => acc + room.passengers.length, 0);

    const formatNames = (passengers) => {
        return passengers
            .map((passenger) => passenger.fullName)
            .filter(name => name.trim() !== '')
            .join(' y ');
    };

    let globalRoomNumber = 1;

    const renderRoomType = (title, rooms) => {
        if (rooms.length === 0) return null;

        return (
            <>
                <Text style={styles.roomTypeTitle}>{rooms.length} {title.toUpperCase()}</Text>
                <View style={styles.roomContainer}>
                    {rooms.map((room, index) => (
                        <View key={index} style={styles.roomItem} wrap={false}>
                            <Text style={styles.passengerNames}>{globalRoomNumber++} {formatNames(room.passengers)}</Text>
                        </View>
                    ))}
                </View>
            </>
        );
    };

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Encabezado */}
                <View style={styles.header}>
                    <View style={styles.headerTop}>
                        <Text style={styles.title}>Rooming-list</Text>
                        <View style={styles.regimeBox}>
                            <Text>Régimen</Text>
                            <Text>{hotelInfo.totalRegimen || 'No especificado'}</Text>
                        </View>
                    </View>
                    <View style={styles.hotelInfo}>
                        <Text style={{ fontWeight: 'bold' }}>HOTEL: {hotelInfo.name || 'No especificado'}</Text>
                        <Text>{hotelInfo.address || 'No especificado'}</Text>
                        <Text>
                            {hotelInfo.postalCode || 'No especificado'} {hotelInfo.city || 'No especificado'}, {hotelInfo.province || 'No especificado'}
                        </Text>
                        <Text>Tel: {hotelInfo.telephoneNumber || 'No especificado'}</Text>
                    </View>
                    <Image src={logo} style={styles.logo} />
                </View>

                {/* Nueva sección para Expedient Number y Group Name */}
                <View style={styles.regimeBox}>
                    <Text style={styles.expedientNumber}>Expediente: {expedientNumber || 'No especificado'}</Text>
                    <Text style={styles.groupName}>Grupo: {groupName || 'No especificado'}</Text>
                </View>

                {/* Datos de referencia */}
                <View style={styles.referenceInfo}>
                    <Text style={styles.referenceText}>
                        Entrada: {formatDate(hotelInfo.entryDate)} con {hotelInfo.entryRegimen || 'No especificado'}
                    </Text>
                    <Text style={styles.referenceText}>
                        Salida: {formatDate(hotelInfo.exitDate)} con {hotelInfo.exitRegimen || 'No especificado'}
                    </Text>
                </View>

                {/* Tabla de habitaciones */}
                <View>
                    {renderRoomType('DOUBLE', groupedRooms.DOUBLE)}
                    {renderRoomType('SINGLE', groupedRooms.SINGLE)}
                    {renderRoomType('TRIPLE', groupedRooms.TRIPLE)}
                </View>

                {/* Totales y Pie de Página */}
                <View style={styles.footer}>
                    <Text style={styles.totalRooms}>Total Habitaciones: {totalRooms}</Text>
                    <Text>Total Personas: {totalPersons}</Text>
                    <Text>Teléfono de Urgencias de Malaka Viajes: 952 41 18 35</Text>
                </View>
            </Page>
        </Document>
    );
};

export default RoomingListPDF;