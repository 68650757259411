// src/components/Header.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/Header.css';
import { FaUserCircle, FaArrowLeft } from 'react-icons/fa';
import authService from '../services/authService';

function Header({ username }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Función para manejar el cierre de sesión
  const handleLogout = () => {
    console.log('Botón de cerrar sesión clickeado');
    authService.logout();
    console.log('Redirigiendo al formulario de login');
    navigate('/');
  };

  // Función para alternar el menú desplegable
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Función para manejar el botón de "Atrás"
  const handleBack = () => {
    const currentPath = location.pathname;

    // Definir una lista de patrones de rutas y sus rutas padres correspondientes
    const pathHierarchy = [
      { pattern: /^\/malaka\/expedients\/edit\/[^/]+$/, parent: '/malaka/expedients' },
      { pattern: /^\/malaka\/expedients$/, parent: '/malaka/' },
      { pattern: /^\/malaka\/facturacion$/, parent: '/malaka/' },
      { pattern: /^\/malaka\/new-expedient$/, parent: '/malaka/' },
      { pattern: /^\/malaka\/.*$/, parent: '/malaka' },
      { pattern: /^\/main$/, parent: '/' },
    ];

    // Iterar sobre los patrones y navegar al padre correspondiente si hay una coincidencia
    for (let i = 0; i < pathHierarchy.length; i++) {
      if (pathHierarchy[i].pattern.test(currentPath)) {
        navigate(pathHierarchy[i].parent);
        return;
      }
    }

    // Comportamiento por defecto si no hay coincidencias
    navigate(-1);
  };

  // Función para cerrar el menú desplegable si se hace clic fuera de él
  const handleClickOutside = (event) => {
    if (!event.target.closest('.user-menu')) {
      setIsDropdownOpen(false);
    }
  };

  // Añadir y limpiar el listener para detectar clics fuera del menú
  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Función para determinar qué accesos directos mostrar según la ruta actual
  const getShortcuts = () => {
    const path = location.pathname;

    if (path.startsWith('/malaka')) {
      return (
        <div className="header-shortcuts">
          <button onClick={() => navigate('/malaka/caja')}>Caja</button>
          <button onClick={() => navigate('/malaka/expedients')}>Expedientes</button>
          <button onClick={() => navigate('/malaka/clients')}>Clientes</button>
          <button onClick={() => navigate('/malaka/suppliers')}>Proveedores</button>
        </div>
      );
    }

    if (path.startsWith('/lauro')) {
      return (
        <div className="header-shortcuts">
          {/* Accesos directos para Lauro cuando esté implementado */}
          <button onClick={() => navigate('/lauro/feature1')}>Feature 1</button>
          <button onClick={() => navigate('/lauro/feature2')}>Feature 2</button>
          {/* Añade más botones según sea necesario */}
        </div>
      );
    }

    return null; // No mostrar accesos directos si no está en /malaka o /lauro
  };

  return (
    <header className="header-container">
      {/* Botón de Atrás */}
      <button className="back-button" onClick={handleBack} aria-label="Atrás">
        <FaArrowLeft size={20} />
      </button>

      {/* Contenedor para centrar los accesos directos */}
      <div className="header-content">
        {getShortcuts()}
      </div>

      {/* Información del Usuario y Menú Desplegable */}
      <div className="user-menu" onClick={toggleDropdown}>
        <FaUserCircle size={30} className="user-icon" />
        <span className="username" title={username}>{username}</span>
        {isDropdownOpen && (
          <div className="dropdown-menu">
            <button className="dropdown-item" onClick={handleLogout}>
              Cerrar Sesión
            </button>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;