// src/components/PassengerListPDF.js

import React, { useMemo } from 'react';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
} from '@react-pdf/renderer';
import logo from '../../images/logo_malaka.png';

// Definir estilos
const styles = StyleSheet.create({
    page: {
        padding: 40,
        fontFamily: 'Helvetica',
        fontSize: 10,
        color: '#333',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: 10,
    },
    headerLeft: {
        flexDirection: 'column',
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    logo: {
        width: 100,
        height: 50,
    },
    infoBox: {
        border: '1 solid #000',
        padding: 5,
        marginBottom: 10,
        textAlign: 'center',
    },
    infoText: {
        fontSize: 12,
    },
    referenceInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 10,
        borderBottom: '1 solid #000',
        paddingBottom: 5,
    },
    referenceText: {
        fontSize: 10,
    },
    passengerListTitle: {
        fontWeight: 'bold',
        marginVertical: 10,
        textAlign: 'center',
        fontSize: 14,
    },
    columnsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    column: {
        width: '48%', // Aproximadamente la mitad del ancho, dejando un pequeño espacio entre columnas
    },
    passengerItem: {
        padding: 5,
        borderBottom: '1 solid #ddd',
    },
    passengerName: {
        fontSize: 12,
    },
    footer: {
        marginTop: 20,
        fontSize: 10,
        textAlign: 'center',
    },
    totalPassengers: {
        fontWeight: 'bold',
        marginTop: 10,
    },
});


/**
 * Componente para generar un PDF con el listado de pasajeros en dos columnas.
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.hotelInfo - Información del hotel.
 * @param {Object} props.roomData - Datos de las habitaciones.
 * @param {string} props.expedientNumber - Número de expediente.
 * @param {string} props.groupName - Nombre del grupo.
 * @returns {JSX.Element} - Documento PDF.
 */
const PassengerListPDF = ({roomData, expedientNumber, groupName }) => {
    // Obtener todos los pasajeros
    const allPassengers = useMemo(() => {
        return roomData.rooms.reduce((acc, room) => {
            room.passengers.forEach(passenger => {
                if (passenger.fullName.trim() !== '') {
                    acc.push(passenger.fullName);
                }
            });
            return acc;
        }, []);
    }, [roomData.rooms]);

    const totalPassengers = allPassengers.length;

    // Dividir la lista de pasajeros en dos columnas
    const half = Math.ceil(allPassengers.length / 2);
    const leftColumn = allPassengers.slice(0, half);
    const rightColumn = allPassengers.slice(half);

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Encabezado */}
                <View style={styles.header}>
                    <View style={styles.headerLeft}>
                        <Text style={styles.title}>Listado de Pasajeros</Text>
                    </View>
                    <Image src={logo} style={styles.logo} />
                </View>

                {/* Información de Expediente y Grupo */}
                <View style={styles.infoBox}>
                    <Text style={styles.infoText}>Expediente: {expedientNumber || 'No especificado'}</Text>
                    <Text style={styles.infoText}>Grupo: {groupName || 'No especificado'}</Text>
                </View>

                {/* Título de la Lista de Pasajeros */}
                <Text style={styles.passengerListTitle}>Lista de Pasajeros</Text>

                {/* Lista de Pasajeros en dos columnas */}
                <View style={styles.columnsContainer}>
                    {/* Columna Izquierda */}
                    <View style={styles.column}>
                        {leftColumn.map((name, index) => (
                            <View key={index} style={styles.passengerItem}>
                                <Text style={styles.passengerName}>{index + 1}. {name}</Text>
                            </View>
                        ))}
                    </View>
                    {/* Columna Derecha */}
                    <View style={styles.column}>
                        {rightColumn.map((name, index) => (
                            <View key={index + half} style={styles.passengerItem}>
                                <Text style={styles.passengerName}>{index + half + 1}. {name}</Text>
                            </View>
                        ))}
                    </View>
                </View>

                {/* Pie de Página */}
                <View style={styles.footer}>
                    <Text style={styles.totalPassengers}>Total de Pasajeros: {totalPassengers}</Text>
                    <Text>Teléfono de Urgencias de Malaka Viajes: 952 41 18 35</Text>
                </View>
            </Page>
        </Document>
    );
};

export default PassengerListPDF;