// src/components/Malaka/MalakaMenu.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/Malaka/MalakaMenu.css';

function MalakaMenu() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="malaka-menu">
      <h1>Malaka</h1>
      <div className="card-grid">
        {/* Tarjeta de Caja */}
        <div
          className="menu-card"
          onClick={() => handleNavigation('/malaka/caja')}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleNavigation('/malaka/caja');
          }}
        >
          <h2>Caja</h2>
          <p>Gestión de caja aún no implementada.</p>
        </div>

        {/* Tarjeta de Gestión de Expedientes */}
        <div
          className="menu-card"
          onClick={() => handleNavigation('/malaka/expedients')}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleNavigation('/malaka/expedients');
          }}
        >
          <h2>Expedientes</h2>
          <p>Ver y gestionar expedientes existentes.</p>
        </div>
        {/* Tarjeta de Gestión de Clientes */}
        <div
          className="menu-card"
          onClick={() => handleNavigation('/malaka/clients')}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleNavigation('/malaka/clients');
          }}
        >
          <h2>Clientes</h2>
          <p>Ver y gestionar clientes.</p>
        </div>
        {/* Tarjeta de Gestión de Proveedores */}
        <div
          className="menu-card"
          onClick={() => handleNavigation('/malaka/suppliers')}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleNavigation('/malaka/suppliers');
          }}
        >
          <h2>Proveedores</h2>
          <p>Ver y gestionar los proveedores.</p>
        </div>
      </div>
    </div>
  );
}

export default MalakaMenu;
