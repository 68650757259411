// src/components/SuppliersViewer.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosConfig';
import styles from '../../styles/Malaka/SuppliersViewer.module.css';

function SuppliersViewer() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const [suppliers, setSuppliers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await axiosInstance.get('/suppliers');
        if (response.status === 200 && response.data) {
          setSuppliers(response.data);
        } else {
          setError('No se pudo obtener la lista de proveedores.');
        }
      } catch (err) {
        console.error('Error al obtener proveedores:', err);
        setError('Ocurrió un error al obtener la lista de proveedores.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSuppliers();
  }, []);

  const filteredSuppliers = suppliers.filter((supplier) =>
    supplier.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) {
    return (
      <div className={styles['travel-form']}>
        <div className={styles.spinner}></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles['travel-form']}>
        <p className={styles.error}>{error}</p>
      </div>
    );
  }

  return (
    <div className={styles['travel-form']}>
      <h1>Lista de Proveedores</h1>

      {/* Botón para crear un nuevo proveedor */}
      <div className={styles['supplier-button-group']}>
        <button
          className={styles['create-button']}
          onClick={() => handleNavigation('/malaka/suppliers/create')}
          aria-label="Crear Nuevo Proveedor"
        >
          Crear Nuevo Proveedor
        </button>
      </div>

      {/* Campo de búsqueda */}
      <div className={styles['form-row']} style={{ marginBottom: '20px' }}>
        <div className={`${styles['form-group']} ${styles['full-width']}`}>
          <label htmlFor="search">Buscar Proveedor</label>
          <input
            type="text"
            id="search"
            name="search"
            placeholder="Ingrese el nombre del proveedor..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {/* Lista de proveedores en tarjetas */}
      {filteredSuppliers.length === 0 ? (
        <p>No se encontraron proveedores que coincidan con la búsqueda.</p>
      ) : (
        <div className={styles['suppliers-grid']}>
          {filteredSuppliers.map((supplier) => (
            <div key={supplier.name} className={styles['supplier-card']}>
              <h3>{supplier.name}</h3>
              <p>
                <strong>Tipo:</strong> {supplier.type}
              </p>
              <p>
                <strong>Código PGC:</strong> {supplier.pgcCode}
              </p>
              <p>
                <strong>NIF:</strong> {supplier.nif || '-'}
              </p>
              <p>
                <strong>Persona de Contacto:</strong> {supplier.contactPerson || '-'}
              </p>
              <p>
                <strong>Cargo:</strong> {supplier.charge || '-'}
              </p>
              <p>
                <strong>Teléfono:</strong> {supplier.telephoneNumber || '-'}
              </p>
              <p>
                <strong>Razón Social:</strong> {supplier.companyName || '-'}
              </p>
              <p>
                <strong>Dirección:</strong> {supplier.address || '-'}
              </p>
              <p>
                <strong>Código Postal:</strong> {supplier.postalCode || '-'}
              </p>
              <p>
                <strong>Localidad:</strong> {supplier.city || '-'}
              </p>
              <p>
                <strong>Provincia:</strong> {supplier.province || '-'}
              </p>
              <p>
                <strong>País:</strong> {supplier.country || '-'}
              </p>
              <p>
                <strong>Email:</strong> {supplier.email || '-'}
              </p>
              <p>
                <strong>Cuenta bancaria:</strong> {supplier.bankAccount || '-'}
              </p>

              {/* Botones de acción */}
              <div className={styles['button-group']} style={{ marginTop: '10px' }}>
                <button
                  className={styles['edit-button']}
                  onClick={() =>
                    handleNavigation(`/malaka/suppliers/edit/${encodeURIComponent(supplier.name)}`)
                  }
                  aria-label={`Editar proveedor ${supplier.name}`}
                >
                  Editar
                </button>
                {/* Botón de eliminación opcional */}
                {/* <button
                  className={styles['delete-button']}
                  onClick={() => handleDelete(supplier.name)}
                  aria-label={`Eliminar proveedor ${supplier.name}`}
                >
                  Eliminar
                </button> */}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SuppliersViewer;