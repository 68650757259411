// CajaTab.js

import React, { useMemo } from 'react';
import styles from '../../styles/Malaka/TravelForm.module.css'; // Asegúrate de que la ruta es correcta

const CajaTab = ({
  charges,
  payments,
  handleChargeChange,
  handlePaymentChange,
  addCharge,
  removeCharge,
  addPayment,
  removePayment,
  suppliersList,
  totalCollected,
  totalPendingToCollect,
  totalPaidToSuppliers,
  totalPendingToPay,
}) => {
  // Calcular total de cobros
  const totalChargesAmount = useMemo(
    () =>
      charges.reduce((sum, charge) => sum + (parseFloat(charge.amount) || 0), 0),
    [charges]
  );

  // Calcular total de pagos
  const totalPaymentsAmount = useMemo(
    () =>
      payments.reduce((sum, payment) => sum + (parseFloat(payment.amount) || 0), 0),
    [payments]
  );

  // Crear un mapa de supplierId a supplierName
  const supplierIdToNameMap = useMemo(() => {
    const map = {};
    suppliersList.forEach((supplier) => {
      map[supplier.id] = supplier.name;
    });
    return map;
  }, [suppliersList]);

  // Calcular total de pagos por proveedor
  const totalPaidPerSupplier = useMemo(() => {
    const totals = {};
    payments.forEach((payment) => {
      const supplierId = payment.supplierId;
      if (supplierId) {
        if (!totals[supplierId]) {
          totals[supplierId] = 0;
        }
        totals[supplierId] += parseFloat(payment.amount) || 0;
      }
    });
    return totals;
  }, [payments]);

  // Agrupar pagos por proveedor
  const paymentsBySupplier = useMemo(() => {
    const groups = {};
    payments.forEach((payment, index) => {
      const supplierId = payment.supplierId || 'unknown';
      if (!groups[supplierId]) {
        groups[supplierId] = {
          supplierId,
          supplierName:
            supplierId !== 'unknown'
              ? supplierIdToNameMap[supplierId]
              : 'Proveedor desconocido',
          payments: [],
          totalPaid: 0,
        };
      }
      groups[supplierId].payments.push({ ...payment, originalIndex: index });
      groups[supplierId].totalPaid += parseFloat(payment.amount) || 0;
    });
    return Object.values(groups);
  }, [payments, supplierIdToNameMap]);

  return (
    <div className={styles.card}>
      <h2>Caja</h2>

      {/* Sección de Cobros */}
      <div className={styles.cashRegisterSection}>
        <h3>Cobros</h3>

        {/* Fila de Encabezado */}
        <div className={styles.paymentsHeader}>
          <div className={styles.headerCell}>Cantidad (€)</div>
          <div className={styles.headerCell}>Fecha</div>
          <div className={styles.headerCell}>Método de Pago</div>
          <div className={styles.headerCell}>Descripción</div>
          <div className={`${styles['headerCell']} ${styles['actionsCell']}`}>Acciones</div>
        </div>

        {/* Lista de Cobros */}
        {charges.map((charge, index) => (
          <div key={index} className={styles.cashRegisterEntry}>
            {/* Campo Cantidad */}
            <div className={styles.cell}>
              <input
                id={`charge-amount-${index}`}
                type="number"
                name="amount"
                placeholder="Cantidad (€)"
                value={charge.amount}
                onChange={(e) => handleChargeChange(index, 'amount', e.target.value)}
                step="0.01"
                required
              />
            </div>

            {/* Campo Fecha */}
            <div className={styles.cell}>
              <input
                id={`charge-date-${index}`}
                type="date"
                name="date"
                placeholder="Fecha"
                value={charge.date}
                onChange={(e) => handleChargeChange(index, 'date', e.target.value)}
                required
              />
            </div>

            {/* Campo Método de Pago */}
            <div className={styles.cell}>
              <select
                id={`charge-method-${index}`}
                name="method"
                value={charge.method}
                onChange={(e) => handleChargeChange(index, 'method', e.target.value)}
                required
              >
                <option value="">Método de Pago</option>
                <option value="cash">Efectivo</option>
                <option value="bankBBVA">Banco BBVA</option>
                <option value="bankSantander">Banco Santander</option>
                <option value="bankUnicaja">Banco Unicaja</option>
                <option value="bankCajamar">Banco Cajamar</option>
              </select>
            </div>

            {/* Campo Descripción */}
            <div className={styles.cell}>
              <input
                id={`charge-description-${index}`}
                type="text"
                name="description"
                placeholder="Descripción"
                value={charge.description}
                onChange={(e) => handleChargeChange(index, 'description', e.target.value)}
                required
              />
            </div>

            {/* Botón para Eliminar Cobro */}
            <div className={`${styles['cell']} ${styles['actionsCell']}`}>
              <button
                type="button"
                className={`${styles['smallButton']} ${styles['removeButton']}`}
                onClick={() => removeCharge(index)}
              >
                Eliminar
              </button>
            </div>
          </div>
        ))}

        {/* Fila de Total de Cobros */}
        <div className={styles.cashRegisterTotalRow}>
          <div className={`${styles['cell']} ${styles['totalLabel']}`}>Total Cobrado:</div>
          <div className={`${styles['cell']} ${styles['totalAmount']}`}>
            {totalChargesAmount.toFixed(2)} €
          </div>
          {/* Celdas vacías para mantener la estructura */}
          <div className={styles.cell}></div>
          <div className={styles.cell}></div>
          <div className={`${styles['cell']} ${styles['actionsCell']}`}></div>
        </div>

        {/* Botón para Añadir Cobro */}
        <button type="button" className={styles.addButton} onClick={addCharge}>
          Añadir Cobro
        </button>
      </div>

      {/* Sección de Pagos */}
      <div className={styles.cashRegisterSection}>
        <h3>Pagos</h3>

        {/* Fila de Encabezado */}
        <div className={styles.paymentsHeader}>
          <div className={styles.headerCell}>Proveedor</div>
          <div className={styles.headerCell}>Cantidad (€)</div>
          <div className={styles.headerCell}>Fecha</div>
          <div className={styles.headerCell}>Método de Pago</div>
          <div className={styles.headerCell}>Descripción</div>
          <div className={`${styles['headerCell']} ${styles['actionsCell']}`}>Acciones</div>
        </div>

        {/* Lista de Pagos agrupados por proveedor */}
        {paymentsBySupplier.map((supplierGroup) => (
          <React.Fragment key={supplierGroup.supplierId}>
            {/* Renderizar las filas de pagos de este proveedor */}
            {supplierGroup.payments.map((payment) => (
              <div key={payment.originalIndex} className={styles.cashRegisterEntry}>
                {/* Selector de Proveedor */}
                <div className={styles.cell}>
                  <select
                    id={`supplierId-${payment.originalIndex}`}
                    name="supplierId"
                    value={payment.supplierId || ''}
                    onChange={(e) =>
                      handlePaymentChange(payment.originalIndex, 'supplierId', e.target.value)
                    }
                    required
                  >
                    <option value="">Seleccione un proveedor</option>
                    {suppliersList.map((supplier) => (
                      <option key={supplier.id} value={supplier.id}>
                        {supplier.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Campo Cantidad */}
                <div className={styles.cell}>
                  <input
                    id={`amount-${payment.originalIndex}`}
                    type="number"
                    name="amount"
                    placeholder="Cantidad (€)"
                    value={payment.amount}
                    onChange={(e) =>
                      handlePaymentChange(payment.originalIndex, 'amount', e.target.value)
                    }
                    step="0.01"
                    required
                  />
                </div>

                {/* Campo Fecha */}
                <div className={styles.cell}>
                  <input
                    id={`date-${payment.originalIndex}`}
                    type="date"
                    name="date"
                    placeholder="Fecha"
                    value={payment.date}
                    onChange={(e) =>
                      handlePaymentChange(payment.originalIndex, 'date', e.target.value)
                    }
                    required
                  />
                </div>

                {/* Campo Método de Pago */}
                <div className={styles.cell}>
                  <select
                    id={`method-${payment.originalIndex}`}
                    name="method"
                    value={payment.method}
                    onChange={(e) =>
                      handlePaymentChange(payment.originalIndex, 'method', e.target.value)
                    }
                    required
                  >
                    <option value="">Método de Pago</option>
                    <option value="cash">Efectivo</option>
                    <option value="bankBBVA">Banco BBVA</option>
                    <option value="bankSantander">Banco Santander</option>
                    <option value="bankUnicaja">Banco Unicaja</option>
                    <option value="bankCajamar">Banco Cajamar</option>
                  </select>
                </div>

                {/* Campo Descripción */}
                <div className={styles.cell}>
                  <input
                    id={`description-${payment.originalIndex}`}
                    type="text"
                    name="description"
                    placeholder="Descripción"
                    value={payment.description}
                    onChange={(e) =>
                      handlePaymentChange(payment.originalIndex, 'description', e.target.value)
                    }
                    required
                  />
                </div>

                {/* Botón para Eliminar Pago */}
                <div className={`${styles['cell']} ${styles['actionsCell']}`}>
                  <button
                    type="button"
                    className={`${styles['smallButton']} ${styles['removeButton']}`}
                    onClick={() => removePayment(payment.originalIndex)}
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            ))}

            {/* Fila de total para este proveedor */}
            <div className={`${styles['cashRegisterTotalRow']} ${styles['supplierTotalRow']}`}>
              <div className={`${styles['cell']} ${styles['totalLabel']}`}>Subtotal:</div>
              <div className={`${styles['cell']} ${styles['totalAmount']}`}>
                {supplierGroup.totalPaid.toFixed(2)} €
              </div>
              {/* Celdas vacías para mantener la estructura */}
              <div className={styles.cell}></div>
              <div className={styles.cell}></div>
              <div className={`${styles['cell']} ${styles['actionsCell']}`}></div>
            </div>
          </React.Fragment>
        ))}

        {/* Fila de Total de Pagos */}
        <div className={styles.cashRegisterTotalRow}>
          <div className={`${styles['cell']} ${styles['totalLabel']}`}>Total Pagado:</div>
          <div className={`${styles['cell']} ${styles['totalAmount']}`}>
            {totalPaymentsAmount.toFixed(2)} €
          </div>
          <div className={styles.cell}></div>
          <div className={styles.cell}></div>
          <div className={`${styles['cell']} ${styles['actionsCell']}`}></div>
        </div>

        {/* Botón para Añadir Pago */}
        <button type="button" className={styles.addButton} onClick={addPayment}>
          Añadir Pago
        </button>
      </div>

      {/* Resumen de Caja */}
      <div className={styles.cashRegisterSummary}>
        <h3>Resumen de Caja</h3>
        <div>
          <div className={styles.summaryItem}>
            <strong>Total Cobrado:</strong> {totalCollected.toFixed(2)} €
          </div>
          <div className={styles.summaryItem}>
            <strong>Total Pendiente por Cobrar:</strong> {totalPendingToCollect.toFixed(2)} €
          </div>
          <div className={styles.summaryItem}>
            <strong>Total Pagado a Proveedores:</strong> {totalPaidToSuppliers.toFixed(2)} €
          </div>
          <div className={styles.summaryItem}>
            <strong>Total Pendiente por Pagar a Proveedores:</strong> {totalPendingToPay.toFixed(2)} €
          </div>
        </div>
      </div>
    </div>
  );
};

export default CajaTab;