import React from 'react';
import { Navigate } from 'react-router-dom';
import authService from '../services/authService';

const ProtectedRoute = ({ children }) => {
    const user = authService.getCurrentUser();

    if (!user || !user.accessToken) {
        console.log('Usuario no autenticado, redirigiendo al login');
        return <Navigate to="/" replace />;
    }

    // Si el usuario está autenticado, renderizar el componente hijo
    return children;
};

export default ProtectedRoute;