import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import MainMenu from './components/MainMenu';
import MalakaMenu from './components/Malaka/MalakaMenu';
import TravelForm from './components/Malaka/TravelForm';
import ExpedientsViewer from './components/Malaka/ExpedientsViewer';
import ClientsViewer from './components/Malaka/ClientsViewer';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './components/Layout'; // Importa el Layout
import ClientForm from './components/Malaka/ClientForm';
import SuppliersViewer from './components/Malaka/SuppliersViewer';
import SupplierForm from './components/Malaka/SupplierForm';
import CajaViewer from './components/Malaka/CajaViewer';
// import NotFound from './components/NotFound'; // Opcional: Ruta para 404

function App() {
  return (
    <Router>
      <Routes>
        {/* Ruta de inicio de sesión */}
        <Route
          path="/"
          element={
            <Login />
          }
        />

        {/* Rutas protegidas utilizando Layout */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          {/* Ruta del menú principal */}
          <Route
            path="main"
            element={<MainMenu />}
          />

          {/* Ruta del menú Malaka Viajes */}
          <Route
            path="malaka"
            element={<MalakaMenu />}
          />

          {/* Ruta para introducir nuevo expediente */}
          <Route
            path="malaka/new-expedient"
            element={<TravelForm />}
          />

          {/* Ruta para listar expedientes */}
          <Route
            path="malaka/expedients"
            element={<ExpedientsViewer />}
          />

          {/* Ruta para editar expediente */}
          <Route
            path="malaka/expedients/edit/:expedientNumber"
            element={<TravelForm />}
          />

          {/* Ruta para listar clientes */}
          <Route
            path="malaka/clients"
            element={<ClientsViewer />}
          />

          {/* Rutas para Proveedores */}
          <Route path="malaka/suppliers" element={<SuppliersViewer />} />
          <Route path="malaka/suppliers/create" element={<SupplierForm />} />
          <Route path="malaka/suppliers/edit/:name" element={<SupplierForm />} />

          {/* Ruta para crear clientes */}
          <Route
            path="malaka/new-client"
            element={<ClientForm />}
          />

          {/* Ruta para editar clientes */}
          <Route
            path="/malaka/client/edit/:pgcCode"
            element={<ClientForm />}
          />

          {/* Ruta para la nueva sección de Caja */}
          <Route path="malaka/caja" element={<CajaViewer />} />

          {/* Ruta de redirección para rutas no definidas dentro de protegidas */}
          <Route path="*" element={<Navigate to="/main" />} />
        </Route>

        {/* Ruta de redirección para rutas no definidas fuera de protegidas */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
