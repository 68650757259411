// src/components/ClientsViewer.js

import React, { useState, useEffect, useMemo } from 'react';
import '../../styles/Malaka/ClientsViewer.css';
import { FaEdit, FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosConfig';

// Component for each client card
const ClientCard = ({ client, navigate }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  const handleEdit = (e) => {
    e.stopPropagation(); // Prevent click on "Edit" from toggling the card
    const encodedPgcCode = encodeURIComponent(client.pgcCode);
    const targetRoute = `/malaka/client/edit/${encodedPgcCode}`;
    navigate(targetRoute);
  };

  return (
    <div
      className={`client-card ${isOpen ? 'open' : ''}`}
      onClick={toggleCard}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter') toggleCard();
      }}
      aria-expanded={isOpen}
      aria-label={`Cliente ${client.name}`}
    >
      <div className="client-header">
        <div>
          <h3>
            Nombre: {client.name}
          </h3>
          <p>Código PGC: {client.pgcCode}</p>
          <p>Razón Social: {client.companyName}</p>
        </div>
        <button
          className="edit-button"
          onClick={handleEdit}
          aria-label={`Editar Cliente ${client.name}`}
        >
          <FaEdit /> Editar
        </button>
      </div>
      {isOpen && (
        <div className="client-details">
          <p>
            <strong>Tipo de Cliente:</strong> {client.clientType}
          </p>
          <p>
            <strong>Rango de visitas:</strong> {client.visitRange}
          </p>
          <p>
            <strong>Dirección:</strong> {client.address}
          </p>
          <p>
            <strong>NIF:</strong> {client.nif}
          </p>
          <p>
            <strong>Teléfono:</strong> {client.telephoneNumber}
          </p>
          <p>
            <strong>Email:</strong> {client.email}
          </p>
          <p>
            <strong>Código Postal:</strong> {client.postalCode}
          </p>
          <p>
            <strong>Localidad:</strong> {client.city}
          </p>
          <p>
            <strong>Provincia:</strong> {client.province}
          </p>
          <p>
            <strong>Notas:</strong> {client.notes}
          </p>
        </div>
      )}
    </div>
  );
};

function ClientsViewer() {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [filter, setFilter] = useState('');
  const [sortKey, setSortKey] = useState('pgcCode');
  const [sortOrder, setSortOrder] = useState('asc');

  // Fetch clients from the server
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axiosInstance.get('/clients'); // Ruta de tu API
        setClients(response.data);
      } catch (error) {
        console.error('Error al obtener clientes:', error);
      }
    };
    fetchClients();
  }, []);

  // Handle change in search filter
  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  // Handle change in sorting
  const handleSortChange = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortOrder('asc');
    }
  };

  // Filter and sort clients
  const filteredAndSortedClients = useMemo(() => {
    const filtered = clients.filter((client) => {
      const lowerCaseFilter = filter.toLowerCase();
      return (
        String(client.pgcCode).toLowerCase().includes(lowerCaseFilter) ||
        client.name?.toLowerCase().includes(lowerCaseFilter) ||
        client.companyName?.toLowerCase().includes(lowerCaseFilter)
      );
    });

    const sorted = filtered.sort((a, b) => {
      let valueA = a[sortKey];
      let valueB = b[sortKey];

      if (sortKey === 'apertureDate' || sortKey === 'closureDate') {
        valueA = new Date(a[sortKey]);
        valueB = new Date(b[sortKey]);
      }

      if (typeof valueA === 'string') valueA = valueA.toLowerCase();
      if (typeof valueB === 'string') valueB = valueB.toLowerCase();

      if (sortOrder === 'asc') {
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      } else {
        if (valueA > valueB) return -1;
        if (valueA < valueB) return 1;
        return 0;
      }
    });

    return sorted;
  }, [clients, filter, sortKey, sortOrder]);

  return (
    <div className="clients-viewer">
      <h2>Clientes</h2>

      {/* Search Bar */}
      <div className="search-bar">
        <FaSearch className="search-icon" aria-hidden="true" />
        <input
          type="text"
          placeholder="Buscar por Código PGC, Nombre o Razón Social"
          value={filter}
          onChange={handleFilterChange}
          className="search-input"
          aria-label="Buscar clientes"
        />
      </div>

      {/* Sort Controls */}
      <div className="sort-controls">
        <button
          onClick={() => handleSortChange('pgcCode')}
          className="sort-button"
          aria-label={`Ordenar por Código PGC ${sortKey === 'pgcCode' ? (sortOrder === 'asc' ? 'ascendente' : 'descendente') : 'ascendente'}`}
        >
          Ordenar por Código PGC {sortKey === 'pgcCode' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
        </button>
        <button
          onClick={() => handleSortChange('name')}
          className="sort-button"
          aria-label={`Ordenar por Nombre ${sortKey === 'name' ? (sortOrder === 'asc' ? 'ascendente' : 'descendente') : 'ascendente'}`}
        >
          Ordenar por Nombre {sortKey === 'name' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
        </button>
        <button
          onClick={() => handleSortChange('companyName')}
          className="sort-button"
          aria-label={`Ordenar por Razón Social ${sortKey === 'companyName' ? (sortOrder === 'asc' ? 'ascendente' : 'descendente') : 'ascendente'}`}
        >
          Ordenar por Razón Social {sortKey === 'companyName' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
        </button>
      </div>

      {/* Button to Create New Client */}
      <div className="create-button-container">
        <button
          className="create-button"
          onClick={() => navigate('/malaka/new-client')}
          aria-label="Crear Nuevo Cliente"
        >
          Crear Nuevo Cliente
        </button>
      </div>

      {/* List of Clients */}
      {filteredAndSortedClients.length === 0 ? (
        <p className="no-clients">No hay clientes que coincidan con la búsqueda.</p>
      ) : (
        <div className="clients-list">
          {filteredAndSortedClients.map((client) => {
            return (
              <ClientCard
                key={client.pgcCode}
                client={client}
                navigate={navigate}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ClientsViewer;