// src/components/RoomingTab.js

import React, { useState, useEffect, useMemo } from 'react';
import styles from '../../styles/Malaka/RoomingTab.module.css';
import RoomingListPDF from './RoomingListPDF';
import PassengerListPDF from './PassengerListPDF'; // Importar el nuevo componente
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import axiosInstance from '../../services/axiosConfig';

const RoomingTab = ({ formData, setFormData }) => {
    // Estado para controlar cuál hotel se está previsualizando
    const [previewingHotel, setPreviewingHotel] = useState(null);

    // Estado para controlar la previsualización del listado de pasajeros
    const [previewingPassengerList, setPreviewingPassengerList] = useState(false);

    // Memorizar los proveedores de tipo 'Hotel' para evitar recrearlos en cada render
    const hotelSuppliers = useMemo(() => {
        return formData.suppliers.filter(supplier => supplier.type === 'Hotel');
    }, [formData.suppliers]);

    // Estado para almacenar la información de todos los hoteles
    const [hotelInfos, setHotelInfos] = useState([]);

    // Obtener la información detallada de cada hotel desde el backend y combinarla con los datos específicos del proveedor
    useEffect(() => {
        const fetchHotelInfos = async () => {
            try {
                const hotelInfoPromises = hotelSuppliers.map(supplier =>
                    axiosInstance.get(`/supplier/byName?name=${encodeURIComponent(supplier.name)}`)
                        .then(response => ({
                            ...response.data,
                            ...supplier, // Datos específicos del proveedor (fechas, régimen, etc.)
                        }))
                );
                const fetchedHotelInfos = await Promise.all(hotelInfoPromises);
                setHotelInfos(fetchedHotelInfos);
            } catch (error) {
                console.error('Error al obtener la información de los hoteles:', error);
                alert('Ocurrió un error al obtener la información de los hoteles.');
            }
        };

        if (hotelSuppliers.length > 0) {
            fetchHotelInfos();
        } else {
            setHotelInfos([]);
        }
    }, [hotelSuppliers]);

    // Funciones para agregar habitaciones
    const addRoom = (roomType, defaultPassengerName = '') => {
        let initialPassengers = [{ fullName: defaultPassengerName }]; // Por defecto, un pasajero

        // Determinar el número inicial de pasajeros según el tipo de habitación
        if (roomType === 'DOUBLE') {
            initialPassengers = [{ fullName: '' }, { fullName: '' }];
        } else if (roomType === 'TRIPLE') {
            initialPassengers = [{ fullName: '' }, { fullName: '' }, { fullName: '' }];
        }

        setFormData((prevData) => ({
            ...prevData,
            rooms: [
                ...prevData.rooms,
                {
                    roomType,
                    passengers: initialPassengers,
                },
            ],
        }));
    };

    // Funciones para manejar cambios en los campos de los pasajeros
    const handlePassengerChange = (roomIndex, passengerIndex, event) => {
        const { value } = event.target;
        const updatedRooms = [...formData.rooms];
        updatedRooms[roomIndex].passengers[passengerIndex].fullName = value;

        setFormData((prevData) => ({
            ...prevData,
            rooms: updatedRooms,
        }));
    };

    // Funciones para eliminar una habitación
    const removeRoom = (roomIndex) => {
        setFormData((prevData) => {
            const updatedRooms = prevData.rooms.filter((_, i) => i !== roomIndex);
            return {
                ...prevData,
                rooms: updatedRooms,
            };
        });
    };

    // Agrupar habitaciones por tipo
    const roomsByType = useMemo(() => {
        const grouped = {
            SINGLE: [],
            DOUBLE: [],
            TRIPLE: [],
        };
        formData.rooms.forEach((room, index) => {
            if (grouped[room.roomType]) {
                grouped[room.roomType].push({ ...room, roomIndex: index });
            }
        });
        return grouped;
    }, [formData.rooms]);

    // Data structure for the PDFs
    const roomData = {
        rooms: formData.rooms,
    };

    // Preparar los datos para el PDF
    const expedientNumberPDF = formData.expedientNumber;
    const groupNamePDF = formData.groupName;

    return (
        <div className={styles.roomingTab}>
            {/* Nueva sección para Descargar y Previsualizar el Listado de Pasajeros */}
            <div className={styles.passengerListSection}>
                <h3>Listado para el seguro</h3>
                {hotelInfos.length > 0 ? (
                    <>
                        {/* Botón para Previsualizar el Listado de Pasajeros */}
                        <button
                            type="button"
                            className={styles.previewPdfButton}
                            onClick={() => setPreviewingPassengerList(true)}
                        >
                            Previsualizar Listado
                        </button>

                        {/* Botón para Descargar el Listado de Pasajeros */}
                        <PDFDownloadLink
                            document={
                                <PassengerListPDF
                                    roomData={roomData}
                                    expedientNumber={expedientNumberPDF}
                                    groupName={groupNamePDF}
                                />
                            }
                            fileName={`Listado_Pasajeros_${expedientNumberPDF || 'Nuevo'}.pdf`}
                            className={styles.downloadPdfLinkButton}
                        >
                            {({ loading }) => (loading ? 'Generando PDF...' : 'Descargar Listado')}
                        </PDFDownloadLink>

                        {/* Previsualización del Listado de Pasajeros */}
                        {previewingPassengerList && (
                            <div className={styles.pdfPreviewContainer}>
                                <div className={styles.pdfPreviewHeader}>
                                    <h3>Previsualización del Listado de Pasajeros</h3>
                                    <button
                                        type="button"
                                        onClick={() => setPreviewingPassengerList(false)}
                                        className={styles.closePreviewButton}
                                        aria-label="Cerrar Previsualización"
                                    >
                                        &times;
                                    </button>
                                </div>
                                <PDFViewer width="100%" height="600px">
                                    <PassengerListPDF
                                        hotelInfo={hotelInfos[0]}
                                        roomData={roomData}
                                        expedientNumber={expedientNumberPDF}
                                        groupName={groupNamePDF}
                                    />
                                </PDFViewer>
                            </div>
                        )}
                    </>
                ) : (
                    <p>No hay información de hotel disponible para generar el listado de pasajeros.</p>
                )}
            </div>

            {/* Botones para cada hotel: Previsualizar y Descargar PDF */}
            <div className={styles.hotelPdfs}>
                {hotelInfos.length > 0 ? (
                    hotelInfos.map((hotel, index) => (
                        <div key={hotel.id || index} className={styles.hotelPdfSection}>
                            <h3>{hotel.name}</h3>
                            {/* Botón para Previsualizar el PDF */}
                            <button
                                type="button"
                                onClick={() => setPreviewingHotel(hotel)}
                                className={styles.previewPdfButton}
                            >
                                Previsualizar PDF
                            </button>
                            {/* Botón para Descargar el PDF */}
                            <PDFDownloadLink
                                document={
                                    <RoomingListPDF
                                        hotelInfo={hotel}
                                        roomData={roomData}
                                        expedientNumber={expedientNumberPDF}
                                        groupName={groupNamePDF}
                                    />
                                }
                                fileName={`Listado_Habitaciones_${expedientNumberPDF || 'Nuevo'}_${hotel.name.replace(/\s+/g, '_')}.pdf`}
                                className={styles.downloadPdfLinkButton}
                            >
                                {({ loading }) => (loading ? 'Generando PDF...' : 'Descargar PDF')}
                            </PDFDownloadLink>
                        </div>
                    ))
                ) : (
                    <p>No hay hoteles asignados para este expediente.</p>
                )}
            </div>

            {/* Previsualización del PDF de Rooming List */}
            {previewingHotel && (
                <div className={styles.pdfPreviewContainer}>
                    <div className={styles.pdfPreviewHeader}>
                        <h3>Previsualización del PDF - {previewingHotel.name}</h3>
                        <button
                            type="button"
                            onClick={() => setPreviewingHotel(null)}
                            className={styles.closePreviewButton}
                            aria-label="Cerrar Previsualización"
                        >
                            &times;
                        </button>
                    </div>
                    <PDFViewer width="100%" height="600px">
                        <RoomingListPDF
                            hotelInfo={previewingHotel}
                            roomData={roomData}
                            expedientNumber={expedientNumberPDF}
                            groupName={groupNamePDF}
                        />
                    </PDFViewer>
                </div>
            )}

            <h2>Listado de Pasajeros y Asignación de Habitaciones</h2>

            {/* Contadores de habitaciones y personas */}
            <div className={styles.roomCounters}>
                <div className={styles.roomCounter}>
                    <div className={styles.countTitle}>Habitaciones Individuales</div>
                    <div className={styles.countNumber}>{roomsByType.SINGLE.length}</div>
                </div>
                <div className={styles.roomCounter}>
                    <div className={styles.countTitle}>Habitaciones Dobles</div>
                    <div className={styles.countNumber}>{roomsByType.DOUBLE.length}</div>
                </div>
                <div className={styles.roomCounter}>
                    <div className={styles.countTitle}>Habitaciones Triples</div>
                    <div className={styles.countNumber}>{roomsByType.TRIPLE.length}</div>
                </div>
                <div className={styles.roomCounter}>
                    <div className={styles.countTitle}>Total de Personas</div>
                    <div className={styles.countNumber}>
                        {formData.rooms.reduce((total, room) => total + room.passengers.length, 0)}
                    </div>
                </div>
            </div>

            {/* Sección de Habitaciones por Tipo */}
            <div className={styles.roomsContainer}>
                {/* Habitaciones Individuales */}
                <div className={styles.roomTypeSection}>
                    <h3>Habitaciones Individuales</h3>
                    {/* Botones para añadir habitación para Guía y Conductor */}
                    <div className={styles.guideDriverButtons}>
                        <button
                            type="button"
                            className={styles.smallButton}
                            onClick={() => addRoom('SINGLE', 'Guía')}
                        >
                            Guía
                        </button>
                        <button
                            type="button"
                            className={styles.smallButton}
                            onClick={() => addRoom('SINGLE', 'Conductor')}
                        >
                            Conductor
                        </button>
                    </div>
                    {roomsByType.SINGLE.length > 0 ? (
                        <div className={styles.roomsGrid}>
                            {roomsByType.SINGLE.map((room, index) => (
                                <div key={room.roomIndex} className={styles.roomCard}>
                                    <div className={styles.roomCardHeader}>
                                        <h4>Habitación {index + 1}</h4>
                                        <button
                                            type="button"
                                            className={styles.removeRoomButton}
                                            onClick={() => removeRoom(room.roomIndex)}
                                            aria-label={`Eliminar Habitación ${index + 1}`}
                                        >
                                            &times;
                                        </button>
                                    </div>
                                    <div className={styles.passengersList}>
                                        {room.passengers.map((passenger, passengerIndex) => (
                                            <div key={passengerIndex} className={styles.passengerEntry}>
                                                <input
                                                    type="text"
                                                    name="fullName"
                                                    placeholder="Nombre completo"
                                                    value={passenger.fullName}
                                                    onChange={(e) =>
                                                        handlePassengerChange(
                                                            room.roomIndex,
                                                            passengerIndex,
                                                            e
                                                        )
                                                    }
                                                    required
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>No hay habitaciones individuales asignadas.</p>
                    )}
                    {/* Botón para añadir habitación individual */}
                    <button
                        type="button"
                        className={styles.addRoomButton}
                        onClick={() => addRoom('SINGLE')}
                    >
                        Añadir Habitación Individual
                    </button>
                </div>

                {/* Habitaciones Dobles */}
                <div className={styles.roomTypeSection}>
                    <h3>Habitaciones Dobles</h3>
                    {roomsByType.DOUBLE.length > 0 ? (
                        <div className={styles.roomsGrid}>
                            {roomsByType.DOUBLE.map((room, index) => (
                                <div key={room.roomIndex} className={styles.roomCard}>
                                    <div className={styles.roomCardHeader}>
                                        <h4>Habitación {index + 1}</h4>
                                        <button
                                            type="button"
                                            className={styles.removeRoomButton}
                                            onClick={() => removeRoom(room.roomIndex)}
                                            aria-label={`Eliminar Habitación ${index + 1}`}
                                        >
                                            &times;
                                        </button>
                                    </div>
                                    <div className={styles.passengersList}>
                                        {room.passengers.map((passenger, passengerIndex) => (
                                            <div key={passengerIndex} className={styles.passengerEntry}>
                                                <input
                                                    type="text"
                                                    name="fullName"
                                                    placeholder="Nombre completo"
                                                    value={passenger.fullName}
                                                    onChange={(e) =>
                                                        handlePassengerChange(
                                                            room.roomIndex,
                                                            passengerIndex,
                                                            e
                                                        )
                                                    }
                                                    required
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>No hay habitaciones dobles asignadas.</p>
                    )}
                    {/* Botón para añadir habitación doble */}
                    <button
                        type="button"
                        className={styles.addRoomButton}
                        onClick={() => addRoom('DOUBLE')}
                    >
                        Añadir Habitación Doble
                    </button>
                </div>

                {/* Habitaciones Triples */}
                <div className={styles.roomTypeSection}>
                    <h3>Habitaciones Triples</h3>
                    {roomsByType.TRIPLE.length > 0 ? (
                        <div className={styles.roomsGrid}>
                            {roomsByType.TRIPLE.map((room, index) => (
                                <div key={room.roomIndex} className={styles.roomCard}>
                                    <div className={styles.roomCardHeader}>
                                        <h4>Habitación {index + 1}</h4>
                                        <button
                                            type="button"
                                            className={styles.removeRoomButton}
                                            onClick={() => removeRoom(room.roomIndex)}
                                            aria-label={`Eliminar Habitación ${index + 1}`}
                                        >
                                            &times;
                                        </button>
                                    </div>
                                    <div className={styles.passengersList}>
                                        {room.passengers.map((passenger, passengerIndex) => (
                                            <div key={passengerIndex} className={styles.passengerEntry}>
                                                <input
                                                    type="text"
                                                    name="fullName"
                                                    placeholder="Nombre completo"
                                                    value={passenger.fullName}
                                                    onChange={(e) =>
                                                        handlePassengerChange(
                                                            room.roomIndex,
                                                            passengerIndex,
                                                            e
                                                        )
                                                    }
                                                    required
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>No hay habitaciones triples asignadas.</p>
                    )}
                    {/* Botón para añadir habitación triple */}
                    <button
                        type="button"
                        className={styles.addRoomButton}
                        onClick={() => addRoom('TRIPLE')}
                    >
                        Añadir Habitación Triple
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RoomingTab;