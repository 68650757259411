// src/components/ExpedientsViewer.js

import React, { useState, useEffect, useMemo } from 'react';
import '../../styles/Malaka/ExpedientsViewer.css';
import { FaEdit, FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosConfig';

// Function to format dates
const formatDate = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date)) return 'Fecha Inválida';
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

function ExpedientsViewer() {
  const navigate = useNavigate();
  const [expedients, setExpedients] = useState([]);
  const [filter, setFilter] = useState('');
  const [sortKey, setSortKey] = useState('expedientNumber');
  const [sortOrder, setSortOrder] = useState('asc');

  // Fetch expedients from the server
  useEffect(() => {
    const fetchExpedients = async () => {
      try {
        const response = await axiosInstance.get('/expedients'); // Ruta de tu API
        setExpedients(response.data);
      } catch (error) {
        console.error('Error al obtener expedientes:', error);
      }
    };
    fetchExpedients();
  }, []);

  // Handle change in search filter
  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  // Handle change in sorting
  const handleSortChange = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortOrder('asc');
    }
  };

  // Filter and sort expedients
  const filteredAndSortedExpedients = useMemo(() => {
    const filtered = expedients.filter((expedient) => {
      const lowerCaseFilter = filter.toLowerCase();
      return (
        String(expedient.expedientNumber).toLowerCase().includes(lowerCaseFilter) ||
        formatDate(expedient.date).toLowerCase().includes(lowerCaseFilter) ||
        expedient.groupName?.toLowerCase().includes(lowerCaseFilter)
      );
    });

    const sorted = filtered.sort((a, b) => {
      let valueA = sortKey === 'date' ? new Date(a[sortKey]) : a[sortKey];
      let valueB = sortKey === 'date' ? new Date(b[sortKey]) : b[sortKey];

      if (typeof valueA === 'string') valueA = valueA.toLowerCase();
      if (typeof valueB === 'string') valueB = valueB.toLowerCase();

      if (sortOrder === 'asc') {
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      } else {
        if (valueA > valueB) return -1;
        if (valueA < valueB) return 1;
        return 0;
      }
    });

    return sorted;
  }, [expedients, filter, sortKey, sortOrder]);

  return (
    <div className="expedients-viewer">
      <h2>Expedientes</h2>

      {/* Search Bar */}
      <div className="search-bar">
        <FaSearch className="search-icon" aria-hidden="true" />
        <input
          type="text"
          placeholder="Buscar por número, fecha o nombre del grupo"
          value={filter}
          onChange={handleFilterChange}
          className="search-input"
          aria-label="Buscar expedientes"
        />
      </div>

      {/* Sort Controls */}
      <div className="sort-controls">
        <button
          onClick={() => handleSortChange('expedientNumber')}
          className="sort-button"
          aria-label={`Ordenar por Número ${sortKey === 'expedientNumber' ? (sortOrder === 'asc' ? 'ascendente' : 'descendente') : 'ascendente'}`}
        >
          Ordenar por Número {sortKey === 'expedientNumber' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
        </button>
        <button
          onClick={() => handleSortChange('date')}
          className="sort-button"
          aria-label={`Ordenar por Fecha ${sortKey === 'date' ? (sortOrder === 'asc' ? 'ascendente' : 'descendente') : 'ascendente'}`}
        >
          Ordenar por Fecha {sortKey === 'date' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
        </button>
      </div>

      {/* Button to Create New Expedient */}
      <div className="create-button-container">
        <button
          className="create-button"
          onClick={() => navigate('/malaka/new-expedient')}
          aria-label="Crear Nuevo Expediente"
        >
          Crear Nuevo Expediente
        </button>
      </div>

      {/* List of Expedients */}
      {filteredAndSortedExpedients.length === 0 ? (
        <p className="no-expedients">No hay expedientes que coincidan con la búsqueda.</p>
      ) : (
        <div className="expedients-list">
          {filteredAndSortedExpedients.map((expedient) => {
            return (
              <ExpedientCard
                key={expedient.expedientNumber}
                expedient={expedient}
                navigate={navigate}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

// Component for each expedient card
const ExpedientCard = ({ expedient, navigate }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  const handleEdit = (e) => {
    e.stopPropagation(); // Prevent click on "Edit" from toggling the card
    const encodedExpedientNumber = encodeURIComponent(expedient.expedientNumber);
    const targetRoute = `/malaka/expedients/edit/${encodedExpedientNumber}`;
    navigate(targetRoute);
  };

  return (
    <div
      className={`expedient-card ${isOpen ? 'open' : ''}`}
      onClick={toggleCard}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter') toggleCard();
      }}
      aria-expanded={isOpen}
      aria-label={`Expediente ${expedient.expedientNumber}`}
    >
      <div className="expedient-header">
        <div>
          <h3>
            Número: {expedient.expedientNumber}
          </h3>
          <p>Fecha: {formatDate(expedient.date)}</p>
          <p>Grupo: {expedient.groupName}</p>
        </div>
        <button
          className="edit-button"
          onClick={handleEdit}
          aria-label={`Editar Expediente ${expedient.expedientNumber}`}
        >
          <FaEdit /> Editar
        </button>
      </div>
      {isOpen && (
        <div className="expedient-details">
          <p>
            <strong>Destino:</strong> {expedient.destination}
          </p>
          <p>
            <strong>Días:</strong> {expedient.days}
          </p>
          <p>
            <strong>Vendedor:</strong> {expedient.salesman}
          </p>
          <p>
            <strong>Ingresos (€):</strong> {expedient.income}
          </p>
          <p>
            <strong>Costes (€):</strong> {expedient.totalCosts}
          </p>
          <p>
            <strong>Beneficio (€):</strong> {expedient.benefit}
          </p>
        </div>
      )}
    </div>
  );
};

export default ExpedientsViewer;