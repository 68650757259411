// src/components/SupplierForm.js

import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosConfig';
import styles from '../../styles/Malaka/SupplierForm.module.css'; // Importamos los estilos como módulo

function SupplierForm() {
  const { name } = useParams();
  const navigate = useNavigate();
  const isEditMode = Boolean(name);

  const [formData, setFormData] = useState({
    name: '',
    type: '',
    pgcCode: '',
    bankAccount: '',
    nif: '',
    contactPerson: '',
    charge: '',
    telephoneNumber: '',
    companyName: '',
    address: '',
    postalCode: '',
    city: '',
    province: '',
    country: '',
    email: '',
    notes: '',
  });

  const [isLoading, setIsLoading] = useState(isEditMode);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isEditMode) {
      const fetchSupplier = async () => {
        try {
          const response = await axiosInstance.get('/supplier/byName', {
            params: { name },
          });
          if (response.status === 200 && response.data) {
            setFormData({
              id: response.data.id || '',
              name: response.data.name || '',
              type: response.data.type || '',
              pgcCode: response.data.pgcCode || '',
              bankAccount: response.data.bankAccount || '',
              nif: response.data.nif || '',
              contactPerson: response.data.contactPerson || '',
              charge: response.data.charge || '',
              telephoneNumber: response.data.telephoneNumber || '',
              companyName: response.data.companyName || '',
              address: response.data.address || '',
              postalCode: response.data.postalCode || '',
              city: response.data.city || '',
              province: response.data.province || '',
              country: response.data.country || '',
              email: response.data.email || '',
              notes: response.data.notes || '',
            });
          } else {
            setError('No se pudo obtener los datos del proveedor.');
          }
        } catch (err) {
          console.error('Error al obtener el proveedor:', err);
          setError('Ocurrió un error al obtener los datos del proveedor.');
        } finally {
          setIsLoading(false);
        }
      };

      fetchSupplier();
    } else {
      const fetchLastSupplierPgcCode = async () => {
        try {
          const response = await axiosInstance.get('/last-expedient-number');
          if (response.status === 200) {
            const lastPgcCode = response.data.supplierPgcCode;
            setFormData((prevData) => ({
              ...prevData,
              pgcCode: lastPgcCode + 1,
            }));
          }
        } catch (error) {
          console.error('Error al obtener el último número de expediente:', error);
          alert('Error al obtener el último número de expediente.');
        }
      };
      fetchLastSupplierPgcCode();
    }
  }, [isEditMode, name]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.type || !formData.pgcCode) {
      alert('Por favor, completa los campos requeridos (Nombre, Tipo, Código PGC).');
      return;
    }

    try {
      if (isEditMode) {
        const response = await axiosInstance.put('/supplier', formData);
        if (response.status === 200 || response.status === 201) {
          alert('¡Proveedor actualizado exitosamente!');
          navigate('/malaka/suppliers');
        } else {
          alert(`Error al actualizar el proveedor: ${response.status} ${response.statusText}`);
        }
      } else {
        const response = await axiosInstance.post('/supplier', formData);
        if (response.status === 200 || response.status === 201) {
          alert('¡Proveedor creado exitosamente!');
          navigate('/malaka/suppliers');
        } else {
          alert(`Error al crear el proveedor: ${response.status} ${response.statusText}`);
        }
      }
    } catch (err) {
      console.error('Error al enviar el formulario:', err);
      console.log('formData', formData);
      if (err.response) {
        alert(`Error: ${err.response.data.message || err.response.statusText}`);
      } else if (err.request) {
        alert('No se recibió respuesta del servidor.');
      } else {
        alert('Ocurrió un error al configurar la petición.');
      }
    }
  };

  const handleCancel = () => {
    navigate('/malaka/suppliers');
  };

  if (isLoading) {
    return (
      <div className={styles['supplier-form']}>
        <p>Cargando proveedor...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles['supplier-form']}>
        <p className={styles.error}>{error}</p>
      </div>
    );
  }

  return (
    <div className={styles['supplier-form']}>
      <h1>{isEditMode ? 'Editar Proveedor' : 'Crear Nuevo Proveedor'}</h1>

      <form onSubmit={handleSubmit}>
        <div className={styles.card}>
          <h2>Datos del Proveedor</h2>

          {/* Primera fila */}
          <div className={styles['form-group']}>
            <label htmlFor="name">Nombre*</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className={styles['form-group']}>
            <label htmlFor="type">Tipo*</label>
            <select
              id="type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              required
            >
              <option value="">Seleccionar...</option>
              <option value="Restaurant">Restaurante</option>
              <option value="Transport">Transporte</option>
              <option value="Others">Otros</option>
              <option value="Hotel">Hotel</option>
              <option value="Guide">Guía</option>
            </select>
          </div>

          <div className={styles['form-group']}>
            <label htmlFor="pgcCode">Código PGC*</label>
            <input
              type="text"
              id="pgcCode"
              name="pgcCode"
              value={formData.pgcCode}
              onChange={handleChange}
              required
            />
          </div>

          <div className={styles['form-group']}>
            <label htmlFor="nif">NIF</label>
            <input
              type="text"
              id="nif"
              name="nif"
              value={formData.nif}
              onChange={handleChange}
            />
          </div>

          {/* Segunda fila */}
          <div className={styles['form-group']}>
            <label htmlFor="contactPerson">Persona de Contacto</label>
            <input
              type="text"
              id="contactPerson"
              name="contactPerson"
              value={formData.contactPerson}
              onChange={handleChange}
            />
          </div>

          <div className={styles['form-group']}>
            <label htmlFor="charge">Cargo</label>
            <input
              type="text"
              id="charge"
              name="charge"
              value={formData.charge}
              onChange={handleChange}
            />
          </div>

          <div className={styles['form-group']}>
            <label htmlFor="telephoneNumber">Teléfono</label>
            <input
              type="text"
              id="telephoneNumber"
              name="telephoneNumber"
              value={formData.telephoneNumber}
              onChange={handleChange}
            />
          </div>

          <div className={styles['form-group']}>
            <label htmlFor="companyName">Razón Social</label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
            />
          </div>

          {/* Tercera fila */}
          <div className={styles['form-group']}>
            <label htmlFor="address">Dirección</label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>

          <div className={styles['form-group']}>
            <label htmlFor="postalCode">Código Postal</label>
            <input
              type="text"
              id="postalCode"
              name="postalCode"
              value={formData.postalCode}
              onChange={handleChange}
            />
          </div>

          <div className={styles['form-group']}>
            <label htmlFor="city">Localidad</label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
          </div>

          <div className={styles['form-group']}>
            <label htmlFor="province">Provincia</label>
            <input
              type="text"
              id="province"
              name="province"
              value={formData.province}
              onChange={handleChange}
            />
          </div>

          {/* Cuarta fila */}
          <div className={styles['form-group']}>
            <label htmlFor="country">País</label>
            <input
              type="text"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
            />
          </div>

          <div className={styles['form-group']}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          <div className={styles['form-group']}>
            <label htmlFor="bankAccount">Cuenta bancaria</label>
            <input
              type="text"
              id="bankAccount"
              name="bankAccount"
              value={formData.bankAccount}
              onChange={handleChange}
            />
          </div>

          {/* Campo Notas, ocupando toda la fila */}
          <div className={`${styles['form-group']} ${styles['full-width']}`}>
            <label htmlFor="notes">Notas</label>
            <textarea
              id="notes"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Botones de Acción */}
        <div className={styles['button-group']}>
          <button
            type="submit"
            className={styles['submit-button']}
            aria-label={isEditMode ? 'Guardar Cambios' : 'Crear Proveedor'}
          >
            {isEditMode ? 'Guardar Cambios' : 'Crear Proveedor'}
          </button>
          <button
            type="button"
            className={styles['cancel-button']}
            onClick={handleCancel}
            aria-label="Cancelar"
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
}

export default SupplierForm;