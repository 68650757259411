// SummaryTab.js
import React, { useMemo } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import styles from '../../styles/Malaka/SummaryTab.module.css';

const SummaryTab = ({
    formData,
    suppliersList,
    income,
    totalCosts,
    benefit,
    totalCollected,
    totalChargesAmount,
    totalPaymentsAmount,
    totalPendingToCollect,
    totalPendingToPay,
}) => {
    // Calcular los costos por proveedor
    const supplierCosts = useMemo(() => {
        return formData.suppliers.map((supplier, index) => {
            let uniqueCost = 0;
            let costPerPax = 0;
            let sum = 0;
            let priceAdjust = 0;

            if (supplier.type === 'Hotel' || supplier.type === 'Restaurant') {
                costPerPax = parseFloat(supplier.costPerPerson) || 0;
                priceAdjust = parseFloat(supplier.priceAdjust) || 0;
                sum = costPerPax * (parseInt(formData.pax) || 0) + priceAdjust;
            } else {
                uniqueCost = parseFloat(supplier.cost) || 0;
                sum = uniqueCost;
            }

            return {
                supplierId: supplier.supplierId,
                supplierName: supplier.name || `Proveedor ${index + 1}`,
                notes: supplier.notes || '',
                uniqueCost,
                costPerPax,
                priceAdjust,
                sum,
            };
        });
    }, [formData.suppliers, formData.pax]);

    const totalSupplierCosts = supplierCosts.reduce((sum, supplier) => sum + supplier.sum, 0);

    // Calcular lo pendiente y lo pagado a cada proveedor
    const pendingPaymentsPerSupplier = useMemo(() => {
        return supplierCosts.map((supplier) => {
            const totalPaid = formData.payments
                .filter((payment) => payment.supplierId === supplier.supplierId)
                .reduce((acc, payment) => acc + (parseFloat(payment.amount) || 0), 0);

            const pendingToPay = supplier.sum - totalPaid;

            return {
                supplierName: supplier.supplierName,
                totalPaid: totalPaid.toFixed(2),
                pendingToPay: pendingToPay > 0 ? pendingToPay.toFixed(2) : '0.00',
            };
        });
    },
        [supplierCosts, formData.payments]);

    // Función para generar PDF usando jsPDF y jsPDF-Autotable
    const handleGeneratePDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');
        const pageWidth = doc.internal.pageSize.getWidth();
        const margin = 10; // Margen de 10mm
        let currentY = margin;

        // Título
        doc.setFontSize(16);
        doc.text('Resumen Expediente', pageWidth / 2, currentY, { align: 'center' });
        currentY += 10; // Espacio después del título

        doc.setFontSize(10);
        const infoGeneral = [
            { label: 'Nombre del Grupo:', value: formData.groupName },
            { label: 'Destino:', value: formData.destination },
            { label: 'Número Total de Personas que Viajan:', value: totalPeopleTraveling },
            { label: 'Plazas Libres:', value: freeSpots >= 0 ? freeSpots : 0 },
        ];

        infoGeneral.forEach(item => {
            doc.text(`${item.label} ${item.value}`, margin, currentY);
            currentY += 6;
        });

        currentY += 6; // Espacio adicional

        // Tabla de Proveedores
        doc.setFontSize(14);
        doc.text('Detalle de Proveedores', margin, currentY);
        currentY += 6;

        doc.setFontSize(12);
        const supplierTableColumn = ["Proveedor", "Notas", "Coste Único (€)", "Coste/PAX (€)", "Ajuste (€)", "Suma (€)"];
        const supplierTableRows = supplierCosts.map(supplier => [
            supplier.supplierName,
            supplier.notes,
            supplier.uniqueCost.toFixed(2),
            supplier.costPerPax.toFixed(2),
            supplier.priceAdjust.toFixed(2),
            supplier.sum.toFixed(2),
        ]);

        doc.autoTable({
            head: [supplierTableColumn],
            body: supplierTableRows,
            startY: currentY,
            theme: 'grid',
            styles: { fontSize: 10 },
            headStyles: { fillColor: [41, 128, 185], halign: 'left' },
            columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 50 },
                2: { cellWidth: 20, halign: 'right' },
                3: { cellWidth: 20, halign: 'right' },
                4: { cellWidth: 20, halign: 'right' },
                5: { cellWidth: 20, halign: 'right' },
            },
            margin: { left: margin, right: margin },
            didDrawPage: (data) => {
                currentY = data.cursor.y + 5; // Añadir espacio después de la tabla
            },
            willDrawCell: (data) => {
                // Puedes personalizar celdas aquí si es necesario
            },
        });

        // Total Costes Proveedores
        doc.setFontSize(10);
        doc.text(`Total Costes Proveedores: ${totalSupplierCosts.toFixed(2)} €`, margin, currentY);
        currentY += 10; // Espacio después del total

        // Pendiente por Pagar a Cada Proveedor
        doc.setFontSize(14);
        doc.text('Pendiente por Pagar a Cada Proveedor', margin, currentY);
        currentY += 6;
        
        doc.setFontSize(12);
        const pendingTableColumn = ["Proveedor", "Pagado (€)", "Pendiente por Pagar (€)"];
        const pendingTableRows = pendingPaymentsPerSupplier.map(supplier => [
            supplier.supplierName,
            supplier.totalPaid,
            supplier.pendingToPay,
        ]);

        doc.autoTable({
            head: [pendingTableColumn],
            body: pendingTableRows,
            startY: currentY,
            theme: 'grid',
            styles: { fontSize: 10 },
            headStyles: { fillColor: [41, 128, 185], halign: 'left' },
            columnStyles: {
                0: { cellWidth: 70 },
                1: { cellWidth: 40, halign: 'right' },
                2: { cellWidth: 40, halign: 'right' },
            },
            margin: { left: margin, right: margin },
            didDrawPage: (data) => {
                currentY = data.cursor.y + 5; // Añadir espacio después de la tabla
            },
        });

        // Total Pendiente por Pagar
        const totalPendingPay = pendingPaymentsPerSupplier.reduce((acc, supplier) => acc + parseFloat(supplier.pendingToPay), 0).toFixed(2);
        doc.setFontSize(10);
        doc.text(`Total Pendiente por Pagar: ${totalPendingPay} €`, margin, currentY);
        currentY += 12; // Espacio después del total

        // Resumen Financiero
        doc.setFontSize(14);
        doc.text('Resumen Financiero', margin, currentY);
        currentY += 10;

        doc.setFontSize(10);
        const financialSummary = [
            { label: 'PVP (Precio por Persona):', value: `${parseFloat(formData.pvp || 0).toFixed(2)} €` },
            { label: 'PAX (Total de Personas):', value: `x ${parseInt(formData.pax) || 0}` },
            { label: 'Suplemento individual (por implementar):', value: `${0} €` },
            { label: 'Total ventas:', value: `${income.toFixed(2)} €` },
            { label: 'Total costes:', value: `${totalCosts.toFixed(2)} €` },
            { label: 'Beneficio:', value: `${benefit.toFixed(2)} €` },
            { label: 'Total Cobrado:', value: `${totalCollected.toFixed(2)} €` },
            { label: 'Total Pendiente por Cobrar:', value: `${totalPendingToCollect.toFixed(2)} €` },
        ];

        financialSummary.forEach(item => {
            doc.text(`${item.label} ${item.value}`, margin, currentY);
            currentY += 6;
        });

        // Guardar el PDF
        doc.save(`Resumen_Expediente_${formData.expedientNumber}.pdf`);
    };

    // Calcular el número total de personas que viajan
    const totalPeopleTraveling = useMemo(() => {
        return formData.rooms.reduce((total, room) => {
            return total + room.passengers.length;
        }, 0);
    }, [formData.rooms]);

    // Calcular plazas libres (cupo menos total de personas)
    const freeSpots = formData.maxPeople - totalPeopleTraveling;

    return (
        <div className={styles.summaryTab}>
            {/* Botón para generar PDF */}
            <div className={styles.buttonContainer}>
                <button onClick={handleGeneratePDF} type="button" className={styles.generatePdfButton}>
                    Generar PDF
                </button>
            </div>

            {/* Envolver el contenido en un div con id "summary-content" */}
            <div id="summary-content">
                {/* Información adicional */}
                <h3>Información General</h3>
                <table className={styles.summaryTable}>
                    <tbody>
                        <tr>
                            <td><strong>Nombre del Grupo:</strong></td>
                            <td>{formData.groupName}</td>
                        </tr>
                        <tr>
                            <td><strong>Destino:</strong></td>
                            <td>{formData.destination}</td>
                        </tr>
                        <tr>
                            <td><strong>Número Total de Personas que Viajan:</strong></td>
                            <td>{totalPeopleTraveling}</td>
                        </tr>
                        <tr>
                            <td><strong>Plazas Libres:</strong></td>
                            <td>{freeSpots >= 0 ? freeSpots : 0}</td>
                        </tr>
                    </tbody>
                </table>

                {/* Tabla de proveedores */}
                <h3>Detalle de Proveedores</h3>
                <table className={styles.summaryTable}>
                    <thead>
                        <tr>
                            <th>Proveedor</th>
                            <th>Notas</th>
                            <th>Coste Único (€)</th>
                            <th>Coste/PAX (€)</th>
                            <th>Ajuste (€)</th>
                            <th>Suma (€)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {supplierCosts.map((supplier, index) => (
                            <tr key={index}>
                                <td>{supplier.supplierName}</td>
                                <td>{supplier.notes}</td>
                                <td>{supplier.uniqueCost.toFixed(2)}</td>
                                <td>{supplier.costPerPax.toFixed(2)}</td>
                                <td>{supplier.priceAdjust.toFixed(2)}</td>
                                <td>{supplier.sum.toFixed(2)}</td>
                            </tr>
                        ))}
                        <tr className={styles.totalRow}>
                            <td colSpan="5"><strong>Total Costes Proveedores:</strong></td>
                            <td><strong>{totalSupplierCosts.toFixed(2)} €</strong></td>
                        </tr>
                    </tbody>
                </table>

                {/* Nueva Sección: Pendiente por Pagar y Pagado a Cada Proveedor */}
                <h3>Pendiente por Pargar a Cada Proveedor</h3>
                <table className={styles.summaryTable}>
                    <thead>
                        <tr>
                            <th>Proveedor</th>
                            <th>Pagado (€)</th>
                            <th>Pendiente por Pagar (€)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendingPaymentsPerSupplier.map((supplier, index) => (
                            <tr key={index}>
                                <td>{supplier.supplierName}</td>
                                <td>{supplier.totalPaid} €</td>
                                <td>{supplier.pendingToPay} €</td>
                            </tr>
                        ))}
                        {/* Fila de total pendiente por pagar */}
                        <tr className={styles.totalRow}>
                            <td><strong>Total Pendiente por Pagar:</strong></td>
                            <td></td>
                            <td><strong>{pendingPaymentsPerSupplier.reduce((acc, supplier) => acc + parseFloat(supplier.pendingToPay), 0).toFixed(2)} €</strong></td>
                        </tr>
                    </tbody>
                </table>

                {/* Tabla resumen financiero */}
                <h3>Resumen Financiero</h3>
                <table className={styles.summaryTable}>
                    <tbody>
                        <tr>
                            <td><strong>PVP (Precio por Persona):</strong></td>
                            <td>{parseFloat(formData.pvp || 0).toFixed(2)} €</td>
                        </tr>
                        <tr>
                            <td><strong>PAX (Total de Personas):</strong></td>
                            <td>x {parseInt(formData.pax) || 0}</td>
                        </tr>
                        <tr>
                            <td><strong>Suplemento individual (por implementar):</strong></td>
                            <td>{0} €</td>
                        </tr>
                        <tr>
                            <td><strong>Total ventas:</strong></td>
                            <td>{income.toFixed(2)} €</td>
                        </tr>
                        <tr>
                            <td><strong>Total costes:</strong></td>
                            <td>{totalCosts.toFixed(2)} €</td>
                        </tr>
                        <tr>
                            <td><strong>Beneficio:</strong></td>
                            <td>{benefit.toFixed(2)} €</td>
                        </tr>
                        <tr>
                            <td><strong>Total Cobrado:</strong></td>
                            <td>{totalCollected.toFixed(2)} €</td>
                        </tr>
                        <tr>
                            <td><strong>Total Pendiente por Cobrar:</strong></td>
                            <td>{totalPendingToCollect.toFixed(2)} €</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SummaryTab;